/**!
 *	BASE
 *
 *  @name				   base.js (js/ws/base.js)
 *
 *  @client	       CLIENT NAME
 *  @description	 Main frontend application w/ UI features for responsive websites
 *  @copyright 	   (c) 2020 Ansgar Hiller <ansgar@weigelstein.de>
 */

require('bowser/bundled');
import Bowser from 'bowser';
import { Power1, Power2, Linear, TweenMax } from "gsap";
import 'waypoints/src/waypoint.js';
import imagesLoaded from 'imagesloaded';
imagesLoaded.makeJQueryPlugin($);
import enquire from 'enquire.js';

$(document).ready(function()
{   'use strict';

    const DEVICE = Bowser.parse(window.navigator.userAgent);

    if (DEBUG && VERBOSITY === 3)
    {
        console.log(`
            ================================================ \n
            DEVICE DETECTION \n
            ================================================ \n
            Browser:  ${DEVICE.browser.name} (${DEVICE.browser.version}) \n
            OS:       ${DEVICE.os.name} (${DEVICE.os.version}) \n
            Engine:   ${DEVICE.engine.name} (${DEVICE.engine.version}) \n
            Platform: ${DEVICE.platform.type} (${DEVICE.platform.vendor}) \n
            ================================================ \n
        `);
    }
		const
  	// DOM-ELEMENTS
		// uniques
  	BODY     = $('body'),
  	HTML 	   = $('html'),
  	PAGE	   = $('#page'),
    HERO	   = $('#hero'),
  	HEADER   = $('#header'),
  	MAIN 	   = $('#main'),
    SIDEBAR  = $('#sidebar'),
  	FOOTER   = $('#footer'),
		// collections
		hoverables 	= $('.hoverable'),
		toggles 			= $('[data-toggle]');

		/**
		 *  HOVERABLES
		 */
    if (hoverables.length)
    {
        import('./hoverable').then(
                (hoverable) => {
                    hoverable.default(hoverables);
                }
            )
        ;
    }

		/**
		 *  TOGGLE-BUTTONS
		 */
		if (toggles.length)
		{
				if (DEBUG && VERBOSITY >= 1) console.log(`Configuring ${toggles.length} Toggle Buttons`);

				toggles.each(function(i,el)
						{
								var
								_btn    = $(el),
								_target = $(_btn.data('target')),
								_parent = $(_btn.data('parent')),
								_type   = _btn.data('toggle');

								switch (_type)
								{
										case 'dropdown':
										// DROPDOWN (is taken care of by TWBS)
												break;

										case 'popover':
										// POPOVERS (need to be activated)
												_btn.popover();
												break;

										default:
												console.log(_type);
								}
						}
				);
		}

		const APP = {

				ready: false,

				// set on runtime
				w: 0,
				h: 0,
				viewportSize: null,
				isFrontpage: false,
				smallScreenBehavior: false,
				orientation: null,

				// scroll variables
				scrollTop: 0,
				lastScrollTop: 0,

				// device variables
				iOS:        (DEVICE.os.name.toLowerCase() === 'ios'),
				isAndroid:  (DEVICE.os.name.toLowerCase() === 'android'),
				isMobile:   (DEVICE.platform.type === 'mobile'),
				isTablet:   (DEVICE.platform.type === 'tablet'),
				isFirefox:  (DEVICE.browser.name.toLowerCase() === 'firefox'),

				/*
				 *  Cache viewport params for reuse
				 */
				cacheSizes: function()
				{
						this.w = Waypoint.viewportWidth();
						this.h = Waypoint.viewportHeight();
						this.orientation = (this.w > this.h) ? 'landscape' : 'portrait';
						this.scrollTop = $(window).scrollTop();

						if (DEBUG && VERBOSITY >= 2) console.log('viewport: ' + this.w + ' / ' + this.h);
						if (DEBUG && VERBOSITY >= 2) console.log('scrollTop: ' + this.scrollTop);
				},

				/*
				 *  Determines if small screen behavior should be used.
				 *
				 *  @returns {boolean}
				 */
				useSmallScreenBehavior: function()
				{
						this.cacheSizes();
						this.smallScreenBehavior = (this.w < GRID_FLOAT_BREAKPOINT);

						return this.smallScreenBehavior;
				},

				/*
         *  Helper to calc scroll-animation durations
         */
    		getWindowHeight: function(_h)
    		{
      			this.cacheSizes();
            if (_h) {
            	  return this.h + _h;
            } else {
                return this.h;
            }
        },

				/*
         *  Helper to calc hero-element heights
         */
        getHeroHeight: function(_ratio)
        {
            this.cacheSizes();

            var
            _ratio = _ratio || HERO_RATIO_DEFAULT, // -> default 0.5625 (16:9)
            _h = ((this.w * _ratio) < this.h) ? (this.w * _ratio) : this.h;

            /*  DEFINE/ADJUST OUTPUT AS NEEDED  */
            //  return (isFrontpage) ? Math.round(h) : Math.round(_h);
            // return Math.round(_h);

            return (this.h < HERO_MAX_HEIGTHS[APP.viewportSize])? Math.round(this.h) : HERO_MAX_HEIGTHS[APP.viewportSize];
        },

				/*
         *  INIT MAIN APPLICATION
				 *	(Hook: APP-INIT)
         */
				init: function()
    		{
            if(DEBUG) console.log('Event ON-INIT (js/ws/base.js)');

						BODY.addClass(DEVICE.os.name.toLowerCase() + ' ' + DEVICE.platform.type + ' ' + DEVICE.browser.name.toLowerCase());

						$(window).on('images-loaded', function()
            {
                if (!APP.ready)
                {
                    APP.ready = true;
                    APP.start(); // -> APP-START Hook
                }
            });

						this.cacheSizes();

						enquire.register('(min-width: ' + ( BP.xxl ) + 'px)', {
                match: function () { APP.viewportSize = 'xxl'; $(window).trigger('breakpointchange'); }
      			});
      			enquire.register('(min-width: ' + ( BP.xl ) + 'px) and (max-width: ' + ( BP.xxl - 1) + 'px)', {
                match: function () { APP.viewportSize = 'xl'; $(window).trigger('breakpointchange'); }
      			});
      			enquire.register('(min-width: ' + ( BP.lg ) + 'px) and (max-width: ' + ( BP.xl - 1) + 'px)', {
      				  match: function () { APP.viewportSize = 'lg'; $(window).trigger('breakpointchange'); }
      			});
      			enquire.register('(min-width: ' + ( BP.md ) + 'px) and (max-width: ' + ( BP.lg - 1) + 'px)', {
                match: function () { APP.viewportSize = 'md'; $(window).trigger('breakpointchange'); }
      			});
      			enquire.register('(min-width: ' + ( BP.sm ) + 'px) and (max-width: ' + ( BP.md - 1) + 'px)', {
                match: function () { APP.viewportSize = 'sm'; $(window).trigger('breakpointchange'); }
      			});
      			enquire.register('(max-width: ' + ( BP.sm - 1) + 'px)', {
                match: function () { APP.viewportSize = 'xs'; $(window).trigger('breakpointchange'); }
      			});

						// WINDOW-LISTENERS

            $(window)
                .on('scroll-start', function()
              			{
              				  BODY.addClass('scrolling');
                        APP.lastScrollTop = APP.scrollTop;
              			}
                )
                .on('scroll-end', function()
              			{
                        BODY.removeClass('scrolling');
                        APP.scrollTop = $(window).scrollTop();

                        if (DEBUG && VERBOSITY >= 2) console.log(`New srollTop: ${APP.scrollTop}`);
              			}
                )
                .on('breakpointchange', function()
          			{
          				  if (DEBUG && VERBOSITY >= 1) console.log(`breakpointchange: ${APP.viewportSize}`);

                    BODY.removeClass('xs sm md lg xl xxl').addClass(APP.viewportSize);

										APP.Images.swapImagesByBreakpoint($('img'));
          	        APP.Images.swapBgImagesByBreakpoint();

										if (!APP.smallScreenBehavior && APP.useSmallScreenBehavior())
										{
                        if (DEBUG && VERBOSITY >= 1) console.log('SmallScreenBehavior: ON');
                    }
										else if (APP.smallScreenBehavior && !APP.useSmallScreenBehavior())
										{
                        if (DEBUG && VERBOSITY >= 1) console.log('SmallScreenBehavior: OFF');
                    }
          			})
                .trigger('breakpointchange')
            ;

						// update (resize)
      			$(window)
                .on('update', function()
          			{
          				  APP.cacheSizes();

                    var
										_bottomOffset = 0, // f.e. footer-height;
                    _mainH = APP.h + HEADER_HEIGHTS[APP.viewportSize];
										// _mainH = APP.h - HEADER_HEIGHTS[APP.viewportSize];

                    PAGE.css(
                        {
                            minHeight: _mainH + 'px'
                        }
                    );

                    if (MAIN.length)
										{
                        MAIN
                            .css(
																{
																		minHeight: _mainH + 'px'
																}
														)
												;
                    }

                    if ($('.js-hero-pusher').length)
                    {
                        $('.js-hero-pusher').css(
														{
                            		height: _mainH + 'px'
                        		}
											  );
                    }

          			})
                .trigger('update')
            ;

						$(window)
                .on('orientationchange', function(e)
		          			{
												APP.orientation = (APP.orientation === 'landscape')? 'portrait':'landscape';

		                    if (DEBUG && VERBOSITY >= 1) console.log(`Event: ${e.type} (${APP.orientation})`);
		                }
								)
            ;

            var _navi = $('.navbar-collapse');

            // TOGGLE-NAVI
            if ( _navi.length) {
                var NAVI_BACKDROP;

                _navi
                    .on('show.bs.collapse', function(e){
                        if (DEBUG) console.log(e);

                        NAVI_BACKDROP = $('<div />')
                            .addClass('backdrop navi-backdrop');

                        BODY.append(NAVI_BACKDROP);
                    })
                    .on('hide.bs.collapse', function(e){

                        if (DEBUG) console.log(e);

                        var removeNaviBackdrop = function() {
                            NAVI_BACKDROP.detach();
                        };

                        if (NAVI_BACKDROP.length)
                        {
                            TweenMax.to(NAVI_BACKDROP, .3, {
                                opacity: 0,
                                delay: .3,
                                ease: Power1.easeOut,
                                onComplete: removeNaviBackdrop
                            });
                        }
                    })
                ;
            }

						$(window).trigger('APP-INIT',[APP]);

						APP.Images.load(PAGE);
				},

				/*
         *  START MAIN APPLICATION
				 *	(Hook: APP-START)
         */
				start: function()
    		{
            if(DEBUG) console.log('Event ON-START (js/ws/base.js)');

            BODY.removeClass('no-js');

            if (PAGE.hasClass('fade')) PAGE.addClass('show');

            $(window).trigger('APP-START',[APP]);
        }
		}

		/* ============================================================================== */
  	/* IMAGES
  	/*
  	/* @descr:	Manage Imagesloaded events and starts APP when all images are loaded
  	/* @plgin:	ImagesLoaded.js -> comes with the precompiled vendor-scripts
     /*          (js/vendor/vendor.min.js)
  	/* ============================================================================== */

  	APP.Images = {

    		ready: false,
    		IMAGES: Array(),
    		ERRORS: Array(),
    		data: {},

    		load: function ($TARGET, options) {

      			if ($TARGET === undefined) { $TARGET = $BODY; }

      			options = options || {};
      			var opt = {
        				// callbacks
        				bg: 		    options.bg 			     || false,
        				onAlways:	  options.onAlways  	 || null,
        				onSuccess: 	options.onSuccess  	 || null,
        				onFail:    	options.onFail		   || null,
        				onProgress:	options.onProgress	 || null
      			};
      			$.extend(opt, options);

      			/**
      	 		 * This manages the callback functions
      	 		 */
      			var
            _events = $.Callbacks(),
      			_dispatch = function ( fn, params, string ) {
      					if ( typeof fn === 'function' ) {
        						_events
          							.add(fn)
          							.fire( params, string )
          							.remove(fn);
      					}
      			};

      			var _that = this;

    			  $('img.hidden').removeClass('hidden');

      			/* load all images and then start application */
      			$TARGET.imagesLoaded({background: opt.bg}
        				).always(
          					function(obj) {
            						_that.always(obj);
            						_dispatch( opt.onAlways,obj);
          					}
        				).done(
          					function(obj) {
          							_that.success(obj);
          							_dispatch( opt.onSucess,obj);
        						}
        				).fail(
          					function(obj) {
          							_that.fail(obj);
          							_dispatch( opt.onFail,obj);
        						}
        				).progress(
          					function(obj,image) {
          							_that.progress(obj,image);
          							_dispatch( opt.onProgress,obj);
        						}
        				)
            ;

      	},

    		always: function(data) {
      			$(window).trigger('images-loaded',data);

      			this.ready = true;
      			this.data = data;
    		},

    		success: function(data) {
    			   $(window).trigger('images-success',data);
    		},

    		fail: function(data) {
      			$(window).trigger('images-fail',data);
      			if (DEBUG && VERBOSITY >= 1) console.log('WARNING: ' + this.ERRORS.length + ' images are broken or missing:');
      			if ( this.ERRORS.length ) {
        				var _missing = {};
        				for (var i = 0; i < this.ERRORS.length; i++) {
        					  _missing[(i+1)] = this.ERRORS[i].img.src;
        				}
        				if (DEBUG && VERBOSITY >= 1) console.log(_missing);
      			}
    		},

    		progress: function(data,image) {
      			if (!image.isLoaded) //	mend broken images
      			{
        				//	remove 'ready' state
        				$(image.img)
          					.removeClass('ready')
          					.parent()
          					.removeClass('ready');

        				if ($(image.img).hasClass('generated'))
        				{
        					  $(image.img).remove(); // throw out if broken image was dynamically generated ...
        				} else
        				{
        					  $(image.img).addClass('hidden'); // ... or hide
        				}
        				// collect broken images
        				this.ERRORS.push(image);
      			} else
      			{	// collect good images
      				  this.IMAGES.push(image);
      			}
    		},

        swapImagesByBreakpoint: function(elements)
        {
            if (typeof elements !== 'object' ) { elements = $('img'); }

            var bp = APP.viewportSize;

            elements.each(function(i,el)
         		{
                var _placeholder = $(el).attr('src') || APP.Images.PLACEHOLDER_DEFAULT;

                if ($(el).data('lazy') !== undefined)
        				{
                  	if (!$(el).data('original'))
          					{
                      	$(el).data({
                        	  original : $(el).data('lazy')
                      	});
          					}

                    var _newSrc = ( $(el).data(bp) !== undefined ) ? $(el).data(bp) : $(el).data('original');

                    if ( $(el).attr('src') )
          					{
                        $(el)
                            .removeClass('done')
                            .addClass('loading')
                            .attr({
                                src: _newSrc
                            })
                            .imagesLoaded()
                            .progress(function(obj,image)
                            {
                              $(image.img)
                                	.attr('height',(image.img.naturalHeight > 0) ? image.img.naturalHeight : '100%')
                                  .attr('width',(image.img.naturalWidth > 0) ? image.img.naturalWidth : '100%')
                                  .addClass('done')
                                  .removeClass('loading');
                            })
                            .done (function(obj)
                      	    {
                                if (DEBUG && VERBOSITY >= 1) console.log($(obj.elements));
                      	    })
                        ;
                    } else {
                        $(el).data('lazy',_newSrc);
                    }
                }
         		});
            this.lastBp = APP.viewportSize;
        },

        swapBgImagesByBreakpoint: function(elements)
        {
            if (typeof elements !== 'object' ) { elements = $('[data-lazy]'); }

            elements.each(function(i,el)
         		{
                $(el).addClass('loading');

                $(el)
                    .imagesLoaded({background: true})
                    .done(function(obj) {
                        $(obj.elements).removeClass('loading');
             				});

                if ( $(el).data(APP.viewportSize) === undefined )
      					{
         				    $(el).css({'background-image' : 'url(' + $(el).data('lazy') + ')'});
                } else {
                    $(el).css({'background-image' : 'url(' + $(el).data(APP.viewportSize) + ')'});
                }
           	});
        }
  	};

		/* ============================================================================== */
    /*	EVENT HELPER
    /*
    /*	Generic, efficient window resize and scroll handler
    /*	Using 'setTimeout' since Web-Kit and some other browsers call the resize
    /*	function constantly upon window resizing.
    /* ============================================================================== */

    var resizeTimer;
    $(window).resize(function (e) {
        clearTimeout(resizeTimer);
        $(window).trigger('update',e);
        resizeTimer = setTimeout(function () {
            $(window).trigger('delayed-resize',e);
        }, 250);
    });
    var
    scrollTimer,
    scrolling = false;
    $(window).on('scroll', function(e){
        if (!scrolling) {
            scrolling = true;
            $(window).trigger('scroll-start',e);
        }
        clearTimeout(scrollTimer);
        scrollTimer = setTimeout(function(e){
            $(window).trigger('scroll-end',e);
            scrolling = false;
        }, 100);
    });
		APP.init();
});
