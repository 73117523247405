/**!
 *  FX
 *
 *  @name			      slick.js (js/ws/slick.js)
 *
 *  @package       JOOMLA BASE THEME
 *  @description	 Custom implementation of kenwheeler's  slick.js
 *  @copyright 	   (c) 2020 Ansgar Hiller <ansgar@weigelstein.de>
*/

import $ from 'jquery';
import 'waypoints/src/waypoint.js';
import 'slick-carousel';

// DOM-ELEMENTS
const
BODY      = $('body'),
HTML 	 		= $('html'),
HERO 	 		= $('#hero');

class Slider
{
    constructor($slider)
    {
        if (typeof $slider !== 'object') { return false; }

        if (DEBUG) console.log('Slider:constructor (js/ws/slick.js)');

        this.slider = $slider;

        this.opt = {
            type: 'default',
						ratio: this.slider.find('img').first().data('ratio') || HERO_RATIO_DEFAULT,

            // Slick options
            dots: false,
            lazyLoad: 'progressive',
            arrows: (!APP.useSmallScreenBehavior()),
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            fade: true,
            cssEase: 'ease-in-out'
        };

        $.extend(this.opt, this.slider.data());

				if (typeof this.slider.slick === 'function')
				{
						this._init();
				} else {
						if (DEBUG) console.log('ERROR: slick-carousel not imported');
				}

    }

		_init()
		{
				if (DEBUG) console.log('Slider:_init (js/ws/slick.js)');
				var _that = this;

				this.slider
						.on('init setPosition', function(event, slick, direction)
								{
										if (DEBUG) console.log('Slick-Event: ' + event.type);
										var
										_holder = slick.$slider.parent(),
										__w, __h;

										if (_that.opt.type === 'hero')
										{
												__h  = APP.getHeroHeight(_that.opt.ratio);
												slick.$slider.css({ height: __h });
												slick.$slider.find('.slide').css({ height: __h });
										} else {
												__w = slick.slideWidth;
												__h = (__w * _that.opt.ratio);
										}
								}
						)
						.on('lazyLoaded', function(event,slick,direction)
								{
										if (DEBUG) console.log('Slick-Event: ' + event.type);
										APP.Images.swapImagesByBreakpoint( slick.$slider.find('img.figure-img') );
								}
						)
				;

				switch(this.opt.type)
				{
						case 'default':
								this.slider.slick(this.opt);
								// WS.SLICK.initAutoplayTrigger(WS.SLICK.SLIDER[i]);
								// WS.SLICK.initFullscreenModal();
						break;

						//	HERO-SLIDER
						// (mod_djimageslider.hero)
						case 'hero':
								this.slider.slick(this.opt);
								// WS.SLICK.initAutoplayTrigger(WS.SLICK.SLIDER[i]);
						break;

						//	RESPONSIVE-SLIDER
						// (mod_djimageslider.responsive)
						case 'responsive':
								this.slider.slick(this.opt);
								// WS.SLICK.initAutoplayTrigger(WS.SLICK.SLIDER[i]);
						break;
				}
		}
}

export default Slider;
