/**!
 * APP
 *
 *  @name				   app.js (js/app.js)
 *
 *  @package       JOOMLA BASE THEME
 *  @copyright 	   (c) 2020 Ansgar Hiller <ansgar@weigelstein.de>
 */

require('../scss/app.scss');

import $ from 'jquery';
global.$ = $;

import 'bootstrap';
import 'slick-carousel';

import { gsap } from 'gsap';
global.gsap = gsap;

import FX from './ws/fx';
import Utils from './ws/utils';
import Slider from './ws/slider';
global.Utils = Utils;

const
DEBUG = true,
VERBOSITY = 1,

OFFSET_TOP_DEFAULT = 100,
SCROLL_DELAY_DEFAULT = .5,

// Breakpoints
BP = {
	 xs: 0,
	 sm: 576,
	 md: 768,
	 lg: 992,
	 xl: 1200,
  xxl: 1600
},

// Must match @grid-float-breakpoint defined in "_variables.scss"
GRID_FLOAT_BREAKPOINT = BP.lg,

HERO_RATIO_DEFAULT = 0.5625, // 16:9 (0.5625)

HERO_MAX_HEIGTHS = {
    xs: 432,
    sm: 432,
    md: 432,
    lg: 432,
    xl: 675,
   xxl: 900
},

HEADER_HEIGHTS = {
    xs: 60,
    sm: 70,
    md: 80,
    lg: 90,
    xl: 100,
   xxl: 100
};

global.DEBUG = DEBUG;
global.VERBOSITY = VERBOSITY;
global.OFFSET_TOP_DEFAULT = OFFSET_TOP_DEFAULT;
global.SCROLL_DELAY_DEFAULT = SCROLL_DELAY_DEFAULT;
global.HERO_RATIO_DEFAULT = HERO_RATIO_DEFAULT;
global.HERO_MAX_HEIGTHS = HERO_MAX_HEIGTHS;
global.HEADER_HEIGHTS = HEADER_HEIGHTS;
global.GRID_FLOAT_BREAKPOINT = GRID_FLOAT_BREAKPOINT;
global.BP = BP;

import './ws/base';

// HOOk Events are triggered in (js/ws/base.js)
// APP-INIT Hook
$(window).on('APP-INIT', function(e, app)
		{
				global.APP = app;
        global.FX = new FX($('[data-scroll-target]'));
		}
);

// APP-START Hook
$(window).on('APP-START', function(e, app)
		{
        global.FX.initScrollTriggers();

        var
        slider = $('.slick'),
        SLIDERS = [];
        if (slider.length)
        {
            slider.each(function(i,el)
                {
                    SLIDERS[i] = new Slider($(el));
                }
            );
        }
		}
);
