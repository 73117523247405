/**!
 *  FX
 *
 *  @name			      fx.js (js/ws/fx.js)
 *
 *  @package       JOOMLA BASE THEME
 *  @description	 Main frontend application w/ UX Effects
 *  @copyright 	   (c) 2020 Ansgar Hiller <ansgar@weigelstein.de>
*/

import $ from 'jquery';
import 'waypoints/src/waypoint.js';

import { gsap } from 'gsap';
import { Power1, Power2, Linear } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

const
SCROLL_SPEED_DEFAULT  = 0.0007,
SCROLL_OFFSET_DEFAULT = 0,
HERO	   = $('#hero'),
HEADER   = $('#header'),
FOOTER   = $('#footer'),
SIDEBAR  = $('#sidebarInner');

class FX
{
    constructor($trigger)
    {
        if (typeof $trigger !== 'object') { return false; }

        if(DEBUG) console.log('Constructor FX:class (js/ws/fx.js)');

        this.WIN = $(window);
        this.TRIGGER = $trigger;

        this.opt = {
            scrollSpeed: SCROLL_SPEED_DEFAULT,
            scrollOffset: SCROLL_OFFSET_DEFAULT
        };

        $.extend(this.opt, this.TRIGGER.data());

        if (this.TRIGGER.length)
        {
            this.TRIGGER.on('click', (e) => {
                this.handleTrigger(e);
            });
        }
    }

    initScrollTriggers()
    {
        /*
         * SCROLL-ANIMATIONS
         */
        if ( HEADER.length && !APP.useSmallScreenBehavior())
        {
            if (DEBUG) console.log('FX: Config HEADER Animations');

            var
            HEADER_ANI_DURATION = HEADER_HEIGHTS[APP.viewportSize] * 1.25,
            HEADER_ANI_OFFSET   = 1,
            HEADER_OFFSET = (HEADER_HEIGHTS[APP.viewportSize] + 1);

            gsap.fromTo(HEADER, {
                    y: - HEADER_OFFSET,
                    opacity: .2,
                    ease: 'none'
                },{
                    y: -2,
                    opacity: 1,
                    scrollTrigger: {
                        trigger: HEADER,
                        start: HEADER_ANI_OFFSET,
                        end: HEADER_ANI_DURATION,
                        scrub: true
                    }
                }
            );
        }

        if ( HERO.length && !APP.useSmallScreenBehavior())
        {
            if (DEBUG) console.log('FX: Config HERO Animations');

            var
            HERO_ANI_DURATION = APP.getHeroHeight() * 1,
            HERO_ANI_OFFSET   = 1,
            HERO_OFFSET = APP.getHeroHeight();

            gsap.fromTo(HERO, {
                    height: HERO_OFFSET,
                    ease: 'none'
                },{
                    height: (HERO_OFFSET * .5),
                    scrollTrigger: {
                        trigger: HERO,
                        start: HERO_ANI_OFFSET,
                        end: HERO_ANI_DURATION,
                        scrub: true
                    }
                }
            );

            gsap.fromTo(HERO.find('.wave-2-container'), {
                    y: 0,
                    opacity: .35,
                    scaleY: .5,
                    ease: 'none'
                },{
                    y: (HERO_OFFSET * .25),
                    opacity: .9,
                    scaleY: .25,
                    scrollTrigger: {
                        trigger: HERO,
                        start: HERO_ANI_OFFSET,
                        end: HERO_ANI_DURATION,
                        scrub: true
                    }
                }
            );

            gsap.fromTo(HERO.find('.wave-3-container'), {
                    y: -(HERO_OFFSET * .15),
                    opacity: .35,
                    ease: 'none'
                },{
                    y: (HERO_OFFSET * .15),
                    opacity: .95,
                    scrollTrigger: {
                        trigger: HERO,
                        start: HERO_ANI_OFFSET,
                        end: HERO_ANI_DURATION,
                        scrub: true
                    }
                }
            );

            gsap.fromTo(HERO.find('.logo'), {
                    y: 0,
                    ease: 'none'
                },{
                    y: (HERO_OFFSET * .05),
                    scrollTrigger: {
                        trigger: HERO,
                        start: HERO_ANI_OFFSET,
                        end: HERO_ANI_DURATION,
                        scrub: true
                    }
                }
            );

            if (SIDEBAR.length)
            {

                if (DEBUG) console.log('FX: Config SIDEBAR Pin');

                gsap.to(SIDEBAR, {
                    scrollTrigger: {
                        trigger: SIDEBAR,
                        start: HERO_OFFSET * .57,
                        scrub: true,
                        toggleClass: 'stuck',
                        endTrigger: FOOTER,
                        markers: false
                    }
                });

            }

            // Scroll to const OFFSET_TOP_DEFAULT after const SCROLL_DELAY_DEFAULT
            var _that = this;
            var startFnc = function(target)
            {
                _that.scrollTo(target, 0, 0.003);
            };

            gsap.delayedCall(SCROLL_DELAY_DEFAULT, startFnc, [OFFSET_TOP_DEFAULT]);
        }

        $(window).on('breakpointchange orientationchange', function()
        {
            if (DEBUG) console.log('FX: ScrollTrigger:refresh');

            ScrollTrigger.refresh();
        });
    }

    scrollTo(target, offset, speed)
    {
        if (typeof target === 'undefined') return false;

        var
        newpos = (typeof target === 'number') ? target : 0;
        newpos = (typeof target === 'string') ? $(target).offset().top : newpos;

        var
        _offset = offset || SCROLL_OFFSET_DEFAULT,
        _speed  = speed  || SCROLL_SPEED_DEFAULT,
        _currentPos = this.WIN.scrollTop(),
        _newpos = Math.ceil(newpos) - _offset,
        _duration = Math.abs( _currentPos - _newpos ) * _speed;

        if (DEBUG && VERBOSITY >= 2) console.log('current position: ' + _currentPos);
        if (DEBUG && VERBOSITY >= 2) console.log('duration: ' + _duration);

        gsap.to(
            this.WIN,
            {
                duration: _duration,
                scrollTo: {
                    y: target,
                    offsetY: _offset
                },
                ease: Power2.easeInOut
            }
        );
    }

    handleTrigger(e)
    {
        var
        _trigger = $(e.currentTarget),
        _target = _trigger.data('scroll-target');
        this.scrollTo(_target);
    }
}

export default FX;
